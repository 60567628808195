export default {
    login: {
        wl: 'Welcome to',
        tip: 'Please sign-in to your account and submit your proposal',
        name: 'name/email',
        pwdholder: "..........",
        pwd: 'Password',
        remember: 'Remember Me',
        fgtPwd: 'Forgot Password',
        errorTip: 'This field is required',
        loginBtn: 'Sign in',
        platform: 'New on our platform',
        create: 'Create an account'
    },
    register: {
        wl: 'Starts here',
        tip: 'Make your easy and fun',
        name: 'Username',
        nameHolder: 'john',
        email: 'Email',
        phone: 'Mobile Phone',
        phoneHolder: '123456',
        emailHolder: 'john@example.com',
        pwd: 'Password',
        pwdholder: '........',
        errorTip: 'This field is required',
        checkText: 'I agree to privacy policy & terms',
        checkErrorTip: 'Please check the box',
        registerBtn: 'Sign in',
        platform: 'Already have an account',
        create: 'Sign in instead',
        emailgsErrorTip: 'Please enter a valid email address',
        passwordErrorTip: 'Must contain at least two types of characters: letters, numbers, and underscores',
        success: 'register success',
        areaCode: 'the area code cannot be empty',
        areaCodeSelectHolder: 'select'
    },
    forgot: {
        wl: 'Forgot Password',
        tip: `Enter your email and we'll send you instructions to reset your password`,
        email: 'Email',
        emailHolder: 'john@example.com',
        errorTip: 'This field is required',
        forgotBtn: 'send reset email code',
        backText: 'return to login'
    },
    resetPassword: {
        wl: 'Reset Password',
        tip: `Enter your email verification code and  your new password`,
        code: 'code',
        codeHolder: 'Enter your email verification code',
        password: 'new password',
        passwordHolder: 'new password',
        resetBtn: 'reset',
        backText: 'return to send email',
        successTip: 'reset password success!'
    }
}