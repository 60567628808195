import { addressTypeOptions, countryOrRegionOptions } from '@/dicts/en/address'
import { statusOptions, themeOptions } from '@/dicts/en/registerAndsubmit'

const memberCenter = {
  common: {
    saveChanges: 'Save Changes',
    saveToDraft: 'Save to Draft',
    cancel: 'Cancel',
    register: 'Register',
    apply: 'apply',
    completed: 'completed',
    delete: 'Delete',
    confirmClose: 'Confirm closure?',

    enterPlaceholder: 'Please enter',
    countryOrRegionOptions,
    deleteConfirm: 'Are you sure you want to delete this item?',
    process: 'Upload progress'

  },
  head: {
    switchTheme: 'Light and dark switch',
  },
  menu: {
    home: 'Home',
    memberCenter: 'Member Center',
    personalCenter: 'Personal Center',
    general: 'General',
    changePassword: 'Change Password',
    address: 'Address',
    workManagement: 'Work Management',
    registerAndsubmit: 'Register and Submission',
    backToHome: 'Back to Home',
    jumpTip: 'Cannot jump to the current page'
  },
  general: {
    // 上传头像相关
    upload: 'Drag your avatar here, or click Upload',
    uploadTip: 'Allowed JPG, GIF or PNG. Max size of 800KB',
    isAllowedError: 'Only JPG, PNG, and GIF images can be uploaded !',
    isLt800KBError: 'The size of the uploaded image cannot exceed 800KB !',
    // 表单相关
    userName: 'Username',
    email: 'Email',
    mobileNumber: 'Mobile Number',

    userNamePlaceholder: 'Please enter your username',
    emailPlaceholder: 'Please enter your email',
    mobileNumberPlaceholder: 'Please enter your phone',

    userNameRequired: 'Please enter your username',
    emailRequired: 'Please enter your email',
    emailFormatError: 'Please enter a valid email',
    mobileNumberRequired: 'Please enter your phone',
  },
  changePassword: {
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    retypeNewPassword: 'Retype New Password',

    oldPasswordPlaceholder: 'Please enter your old password',
    newPasswordPlaceholder: 'Please enter your new password',
    retypeNewPasswordPlaceholder: 'Please enter your new password again',

    oldPasswordRequired: 'Please enter your old password',
    newPasswordRequired: 'Please enter your new password',
    retypeNewPasswordRequired: 'Please enter your new password again',
    retypeNewPasswordError: 'Does not match the new password, please check and re-enter'
  },
  address: {
    firstName: 'First Name',
    lastName: 'Last Name',
    addressLineOne: 'Address Line 1',
    addressLineTwo: 'Address Line 2',
    postalCode: 'Postal Code',
    countryOrRegion: 'Country / Region',
    city: 'City',
    regionOrCounty: 'Region / County',
    phone: 'Phone',
    addressType: 'Address Type',

    firstNamePlaceholder: 'Please enter your first name',
    lastNamePlaceholder: 'Please enter your last name',
    addressLineOnePlaceholder: 'Please enter your primary mailing address, such as street name and house number, for accurate delivery',
    addressLineTwoPlaceholder: 'Please enter additional address information to help the postman deliver more accurately',
    postalCodePlaceholder: 'please enter your postal code',
    countryOrRegionPlaceholder: 'Please select a country or region',
    cityPlaceholder: 'Please enter your city',
    regionOrCountyPlaceholder: 'Please enter your region or county',
    phonePlaceholder: 'Please enter your phone number',
    addressTypePlaceholder: 'Please select an address type',

    firstNameRequired: 'First Name is required',
    lastNameRequired: 'Last Name is required',
    addressLineOneRequired: 'Address Line 1 is required',
    postalCodeRequired: 'Postal Code is required',
    countryOrRegionRequired: 'Country / Region is required',
    cityRequired: 'City is required',
    regionOrCountyRequired: 'Region / County is required',
    phoneRequired: 'Phone is required',
    addressTypeRequired: 'Address Type is required',

    addressTypeOptions,
  },
  registerAndsubmit: {
    status: 'Status',
    all: 'All',
    draft: 'Draft',
    register: 'Register',
    submit: 'Submit',

    submitWork: 'Submit work',

    themeSelection: 'Theme Selection',
    teamName: 'Team Name',

    memberName: 'Member Name',
    contactInfoEmail: 'Contact Infomation (Email)',
    country: 'Country',
    affiliation: 'Affiliation',

    advisor: 'Advisor',
    contactInfoEmailFTA: 'Contact Infomation for the Advisor (Email)',
    advisorSCountry: 'Country of the Advisor',
    advisorSAffiliation: 'Affiliation of the Advisor',

    themeSelectionPlaceholder: 'Please select a theme',
    teamNamePlaceholder: 'Please enter your team name',
    countryPlaceholder: 'Please select a country or region',
    contactInfoEmailPlaceholder: 'Please enter an email address',

    memberLimit: 'A maximum of four members can be entered',
    advisorLimit: 'A maximum of two advisor can be entered',

    themeSelectionRequired: 'Theme is required',
    teamNameRequired: 'Team Name is required',
    memberNameRequired: 'Member Name is required',
    contactInfoEmailRequired: 'Contact Infomation (Email) is required',
    countryRequired: 'Country is required',
    affiliationRequired: 'Affiliation is required',
    advisorWarning: "Please fill in the advisor's information completely",

    theme: 'Theme',
    membersNum: 'Member Names',
    memberEmails: 'Member E-mail(s)',
    advisorsNum: 'Advisor Names',
    advisorsEmails: 'Advisor E-mail(s)',
    title: 'Title',
    digest: 'Abstact',
    keywords: 'Keywords',
    workUrl: 'File',
    submissionTime: 'Submission time',
    operation: 'Operation',

    statusOptions,
    themeOptions
  }
}

export default memberCenter