import Vue from "vue";
import Vuex from "vuex";
import viewWidth from "./module/viewWidth";
import User from './module/user';
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    viewWidth,
    User
  }
})

export default store;