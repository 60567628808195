// 英语
import menu from './menu.js'
import languages from './languages.js'
import memberCenter from './memberCenter.js'
import home from './home.js'
import Login from './login.js'
import Introduction from './introduction.js'
import RuleDate from './ruleDate.js'
import AdminInfo from './admin.js'
const en = {
  menu,
  languages,
  memberCenter,
  home,
  Login,
  Introduction,
  RuleDate,
  AdminInfo
}

export default en