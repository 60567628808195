import zhCn from './zhCn'
import en from './en'
import ja from './ja'
import es from './es'
import pl from './pl'
import ru from './ru'
import fr from './fr'
import pt from './pt'
import ko from './ko'

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const languagesPkg = {
  zhCn: { // 简体中文
    ...zhCn,
    ...zhLocale
  }, 
  en: { // 英语
    ...en,
    ...enLocale
  },   
  ja,   // 日语
  es,   // 西班牙语
  pl,   // 波兰语
  ru,   // 俄语
  fr,   // 法语
  pt,   // 葡萄牙语
  ko    // 韩语
}

export default languagesPkg