import { LoginAPI } from "@/api/login";
import { adminLogin } from '@/api/admin'
import { getToken, setToken, setAdminToken } from "@/utils/auth";
import Cookies from "js-cookie";
const user = {
    state: {
        token: getToken(),
        userId: '',
        userName: '',
        avatarUrl: sessionStorage.getItem('avatar') || '',
        adminAvatarUrl: sessionStorage.getItem('admavatar') || '',
        adminUserName: ''
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, name) => {
            state.userName = name;
        },
        SET_USEID: (state, userId) => {
            state.userId = userId;
        },
        SET_AVATAR: (state, avatarUrl) => {
            state.avatarUrl = avatarUrl;
        },
        SET_ADM_AVATAR: (state, avatarUrl) => {
            state.adminAvatarUrl = avatarUrl;
        },
        SET_ADM_USERID: (state, userId) => {
            state.adminUserId = userId;
        },
        SET_ADM_USERNAME: (state, username) => {
            state.adminUserName = username;
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const username = userInfo.userName;
            const password = userInfo.password;
            return new Promise((resolve, reject) => {
                LoginAPI({
                    nameOrEmail: username,
                    password: password,
                })
                    .then((res) => {
                        setToken(res.token);
                        Cookies.set('userId', res.userId)
                        Cookies.set('avatar', res.userAvatar);
                        commit('SET_AVATAR', res.avatarBytes);
                        sessionStorage.setItem('avatar', res.avatarBytes);
                        sessionStorage.setItem('logintype', 0);
                        commit("SET_NAME", res.userName);
                        commit("SET_USEID", res.userId);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        setAvatar({ commit }, avatar) {
            commit("SET_AVATAR", avatar);
        },
        adminLogin({ commit }, userInfo) {
            const username = userInfo.userName;
            const password = userInfo.password;
            return new Promise((resolve, reject) => {
                adminLogin({
                    userName: username,
                    password: password,
                })
                    .then((res) => {
                        Cookies.set('admuserId', res.userId)
                        Cookies.set('admavatar', res.userAvatar);
                        setAdminToken(res.token)
                        sessionStorage.setItem('adminAvatarUrl', res.avatarBytes);
                        sessionStorage.setItem('logintype', 1);
                        commit("SET_ADM_USERNAME", res.userName);
                        commit("SET_ADM_USERID", res.userId);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        setAdmAvatar({ commit }, avatar) {
            commit("SET_ADM_AVATAR", avatar);
        },
    },
};

export default user;


