export const themeOptions = [
  {
    value: 1,
    label: 'Lunar Architecture'
  },
  {
    value: 2,
    label: 'Lunar Energy'
  },
  {
    value: 3,
    label: 'Lunar Robot'
  },
  {
    value: 4,
    label: 'Lunar Mobility'
  },
  {
    value: 5,
    label: 'Astronaut Health and Life Support'
  }
]

export const statusOptions = [
  {
    value: 1,
    label: 'Draft'
  },
  {
    value: 2,
    label: 'Register'
  },
  {
    value: 3,
    label: 'Submit'
  }
]

export const prizeOptionsData = [
  { value: '5', label: 'Finalist' },
  { value: '6', label: 'First Prize' },
  { value: '7', label: 'Second Prize' },
  { value: '8', label: 'Third Prize' },
  { value: '9', label: 'Honorable Mention' },
]