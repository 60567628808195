import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from "vue-router";

import Cookies from 'js-cookie'

Vue.use(VueRouter)

const userInfo = Cookies.get('userInfo')
const adminUserInfo = Cookies.get('adminUserInfo')


const routes = [
  {
    path: '',
    redirect: '/index'
  },
  {
    path: '/memberCenter',
    redirect: '/memberCenter/registerAndSubmit',
    component: () => import('@/views/memberCenterLayout/index.vue'),
    children: [
      {
        path: '/memberCenter/general',
        component: () => import('@/views/General/index.vue')
      },
      {
        path: '/memberCenter/changePassword',
        component: () => import('@/views/ChangePassword/index.vue')
      },
      {
        path: '/memberCenter/address',
        component: () => import('@/views/AddressEdit/index.vue')
      },
      {
        path: '/memberCenter/registerAndSubmit',
        component: () => import('@/views/RegisterAndSubmit/index.vue')
      },
    ]
  },
  {
    path: '/index',
    component: () => import('@/views/MainPage/index.vue'),
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: '/home',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: '/introduction',
        component: () => import('@/views/stationSite/index.vue')
      },
      {
        path: '/ruleDate',
        component: () => import('@/views/rulesAndDates/index.vue')
      },
      {
        path: '/jury',
        component: () => import('@/views/juryMembers/index.vue')
      },
      {
        path: '/login',
        component: () => import('@/views/login/index.vue')
      }
    ]
  },
  {
    path: '/admlogin',
    component: () => import('@/views/adminLogin/index.vue')
  },
  {
    path: '/admhome',
    component: () => import('@/views/adminLayout/index.vue'),
    children: [
      {
        path: '/adm/users',
        component: () => import('@/views/adminUsers/index.vue')
      },
      {
        path: '/adm/entries',
        component: () => import('@/views/adminEntries/index.vue')
      },
      {
        path: '/adm/email',
        component: () => import('@/views/adminEmails/index.vue')
      }
    ]
  },
  {
    path: '/download',
    component: () => import('@/views/download/index.vue')
  }
]

const router = new VueRouter({
  routes 
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // console.log('Cookies', userInfo, adminUserInfo);

  // console.log('beforeEach to', to);
  // console.log('beforeEach from', from);
  // console.log('beforeEach next', next);

  // if(to.path.includes('/admin') && to.path !== '/admin/login') {
  //   if(adminUserInfo) {
  //     console.log(111);
  //     next()
  //   } else {
  //     console.log(222);
  //     next('/admin/login')
  //   }
  // }

  next()

})

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};


export default router



