const menu = {
  home: 'Home',
  stationSite: 'Introduction',
  // competitionThemes : 'Competition Themes',
  rulesAndDates: 'Rules & Dates',
  juryMembers: 'Juries',
  register: 'register',
  login: 'Login'
}

export default menu