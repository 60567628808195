const state = {
  viewWidth: 0
}

const mutations = {
  updateViewWidth(state, payload) {
    if(payload.viewWidth) return state.viewWidth = payload.viewWidth;
    const throttle = () =>{
      if(!payload.timer) {
        payload.timer = setTimeout(() => {
          clearTimeout(payload.timer);
          payload.timer = null;
          state.viewWidth = window.innerWidth;
          console.log("视口宽度：" + state.viewWidth);
        }, 500);
      }
    }
    // window.addEventListener('resize', throttle);
  }
}

const actions = {

}

const getters = {
  viewSize(state) {
    if(Number(state.viewWidth) > 600) {
      return 'large';
    } else {
      return 'small';
    }
  } 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}