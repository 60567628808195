import Cookies from 'js-cookie'

const NormalTokenKey = 'Normal-Token'

export function getToken() {
  return Cookies.get(NormalTokenKey)
}

export function setToken(token) {
  return Cookies.set(NormalTokenKey, token)
}

export function removeToken() {
  return Cookies.remove(NormalTokenKey)
}



const AdminTokenKey = 'admin-Token'

export function getAdminToken() {
  return Cookies.get(AdminTokenKey)
}

export function setAdminToken(token) {
  return Cookies.set(AdminTokenKey, token)
}

export function removeAdminToken() {
  return Cookies.remove(AdminTokenKey)
}