export const addressTypeOptions = [
  {
    value: 1,
    label: 'home',
  },
  {
    value: 2,
    label: 'company',
  }
]

export const countryOrRegionOptions = [
  {
    name: "Afghanistan",
    short: "AF",
  },
  {
    name: "Åland Islands",
    short: "AX",
  },
  {
    name: "Albania",
    short: "AL",
  },
  {
    name: "Algeria",
    short: "DZ",
  },
  {
    name: "American Samoa",
    short: "AS",
  },
  {
    name: "Andorra",
    short: "AD",
  },
  {
    name: "Angola",
    short: "AO",
  },
  {
    name: "Anguilla",
    short: "AI",
  },
  {
    name: "Antarctica",
    short: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    short: "AG",
  },
  {
    name: "Argentina",
    short: "AR",
  },
  {
    name: "Armenia",
    short: "AM",
  },
  {
    name: "Aruba",
    short: "AW",
  },
  {
    name: "Australia",
    short: "AU",
  },
  {
    name: "Austria",
    short: "AT",
  },
  {
    name: "Azerbaijan",
    short: "AZ",
  },
  {
    name: "Bahamas",
    short: "BS",
  },
  {
    name: "Bahrain",
    short: "BH",
  },
  {
    name: "Bangladesh",
    short: "BD",
  },
  {
    name: "Barbados",
    short: "BB",
  },
  {
    name: "Belarus",
    short: "BY",
  },
  {
    name: "Belau",
    short: "PW",
  },
  {
    name: "Belgium",
    short: "BE",
  },
  {
    name: "Belize",
    short: "BZ",
  },
  {
    name: "Benin",
    short: "BJ",
  },
  {
    name: "Bermuda",
    short: "BM",
  },
  {
    name: "Bhutan",
    short: "BT",
  },
  {
    name: "Bolivia",
    short: "BO",
  },
  {
    name: "Bonaire, Saint Eustatius and Saba",
    short: "BQ",
  },
  {
    name: "Bosnia and Herzegovina",
    short: "BA",
  },
  {
    name: "Botswana",
    short: "BW",
  },
  {
    name: "Bouvet Island",
    short: "BV",
  },
  {
    name: "Brazil",
    short: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    short: "IO",
  },
  {
    name: "Brunei",
    short: "BN",
  },
  {
    name: "Bulgaria",
    short: "BG",
  },
  {
    name: "Burkina Faso",
    short: "BF",
  },
  {
    name: "Burundi",
    short: "BI",
  },
  {
    name: "Cambodia",
    short: "KH",
  },
  {
    name: "Cameroon",
    short: "CM",
  },
  {
    name: "Canada",
    short: "CA",
  },
  {
    name: "Cape Verde",
    short: "CV",
  },
  {
    name: "Cayman Islands",
    short: "KY",
  },
  {
    name: "Central African Republic",
    short: "CF",
  },
  {
    name: "Chad",
    short: "TD",
  },
  {
    name: "Chile",
    short: "CL",
  },
  {
    name: "China",
    short: "CN",
  },
  {
    name: "Christmas Island",
    short: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    short: "CC",
  },
  {
    name: "Colombia",
    short: "CO",
  },
  {
    name: "Comoros",
    short: "KM",
  },
  {
    name: "Congo (Brazzaville)",
    short: "CG",
  },
  {
    name: "Congo (Kinshasa)",
    short: "CD",
  },
  {
    name: "Cook Islands",
    short: "CK",
  },
  {
    name: "Costa Rica",
    short: "CR",
  },
  {
    name: "Croatia",
    short: "HR",
  },
  {
    name: "Cuba",
    short: "CU",
  },
  {
    name: "Cura&ccedil;ao",
    short: "CW",
  },
  {
    name: "Cyprus",
    short: "CY",
  },
  {
    name: "Czech Republic",
    short: "CZ",
  },
  {
    name: "Denmark",
    short: "DK",
  },
  {
    name: "Djibouti",
    short: "DJ",
  },
  {
    name: "Dominica",
    short: "DM",
  },
  {
    name: "Dominican Republic",
    short: "DO",
  },
  {
    name: "Ecuador",
    short: "EC",
  },
  {
    name: "Egypt",
    short: "EG",
  },
  {
    name: "El Salvador",
    short: "SV",
  },
  {
    name: "Equatorial Guinea",
    short: "GQ",
  },
  {
    name: "Eritrea",
    short: "ER",
  },
  {
    name: "Estonia",
    short: "EE",
  },
  {
    name: "Ethiopia",
    short: "ET",
  },
  {
    name: "Falkland Islands",
    short: "FK",
  },
  {
    name: "Faroe Islands",
    short: "FO",
  },
  {
    name: "Fiji",
    short: "FJ",
  },
  {
    name: "Finland",
    short: "FI",
  },
  {
    name: "France",
    short: "FR",
  },
  {
    name: "French Guiana",
    short: "GF",
  },
  {
    name: "French Polynesia",
    short: "PF",
  },
  {
    name: "French Southern Territories",
    short: "TF",
  },
  {
    name: "Gabon",
    short: "GA",
  },
  {
    name: "Gambia",
    short: "GM",
  },
  {
    name: "Georgia",
    short: "GE",
  },
  {
    name: "Germany",
    short: "DE",
  },
  {
    name: "Ghana",
    short: "GH",
  },
  {
    name: "Gibraltar",
    short: "GI",
  },
  {
    name: "Greece",
    short: "GR",
  },
  {
    name: "Greenland",
    short: "GL",
  },
  {
    name: "Grenada",
    short: "GD",
  },
  {
    name: "Guadeloupe",
    short: "GP",
  },
  {
    name: "Guam",
    short: "GU",
  },
  {
    name: "Guatemala",
    short: "GT",
  },
  {
    name: "Guernsey",
    short: "GG",
  },
  {
    name: "Guinea",
    short: "GN",
  },
  {
    name: "Guinea-Bissau",
    short: "GW",
  },
  {
    name: "Guyana",
    short: "GY",
  },
  {
    name: "Haiti",
    short: "HT",
  },
  {
    name: "Heard Island and McDonald Islands",
    short: "HM",
  },
  {
    name: "Honduras",
    short: "HN",
  },
  {
    name: "Hong Kong",
    short: "HK",
  },
  {
    name: "Hungary",
    short: "HU",
  },
  {
    name: "Iceland",
    short: "IS",
  },
  {
    name: "India",
    short: "IN",
  },
  {
    name: "Indonesia",
    short: "ID",
  },
  {
    name: "Iran",
    short: "IR",
  },
  {
    name: "Iraq",
    short: "IQ",
  },
  {
    name: "Ireland",
    short: "IE",
  },
  {
    name: "Isle of Man",
    short: "IM",
  },
  {
    name: "Israel",
    short: "IL",
  },
  {
    name: "Italy",
    short: "IT",
  },
  {
    name: "Ivory Coast",
    short: "CI",
  },
  {
    name: "Jamaica",
    short: "JM",
  },
  {
    name: "Japan",
    short: "JP",
  },
  {
    name: "Jersey",
    short: "JE",
  },
  {
    name: "Jordan",
    short: "JO",
  },
  {
    name: "Kazakhstan",
    short: "KZ",
  },
  {
    name: "Kenya",
    short: "KE",
  },
  {
    name: "Kiribati",
    short: "KI",
  },
  {
    name: "Kuwait",
    short: "KW",
  },
  {
    name: "Kyrgyzstan",
    short: "KG",
  },
  {
    name: "Laos",
    short: "LA",
  },
  {
    name: "Latvia",
    short: "LV",
  },
  {
    name: "Lebanon",
    short: "LB",
  },
  {
    name: "Lesotho",
    short: "LS",
  },
  {
    name: "Liberia",
    short: "LR",
  },
  {
    name: "Libya",
    short: "LY",
  },
  {
    name: "Liechtenstein",
    short: "LI",
  },
  {
    name: "Lithuania",
    short: "LT",
  },
  {
    name: "Luxembourg",
    short: "LU",
  },
  {
    name: "Macao",
    short: "MO",
  },
  {
    name: "Madagascar",
    short: "MG",
  },
  {
    name: "Malawi",
    short: "MW",
  },
  {
    name: "Malaysia",
    short: "MY",
  },
  {
    name: "Maldives",
    short: "MV",
  },
  {
    name: "Mali",
    short: "ML",
  },
  {
    name: "Malta",
    short: "MT",
  },
  {
    name: "Marshall Islands",
    short: "MH",
  },
  {
    name: "Martinique",
    short: "MQ",
  },
  {
    name: "Mauritania",
    short: "MR",
  },
  {
    name: "Mauritius",
    short: "MU",
  },
  {
    name: "Mayotte",
    short: "YT",
  },
  {
    name: "Mexico",
    short: "MX",
  },
  {
    name: "Micronesia",
    short: "FM",
  },
  {
    name: "Moldova",
    short: "MD",
  },
  {
    name: "Monaco",
    short: "MC",
  },
  {
    name: "Mongolia",
    short: "MN",
  },
  {
    name: "Montenegro",
    short: "ME",
  },
  {
    name: "Montserrat",
    short: "MS",
  },
  {
    name: "Morocco",
    short: "MA",
  },
  {
    name: "Mozambique",
    short: "MZ",
  },
  {
    name: "Myanmar",
    short: "MM",
  },
  {
    name: "Namibia",
    short: "NA",
  },
  {
    name: "Nauru",
    short: "NR",
  },
  {
    name: "Nepal",
    short: "NP",
  },
  {
    name: "Netherlands",
    short: "NL",
  },
  {
    name: "New Caledonia",
    short: "NC",
  },
  {
    name: "New Zealand",
    short: "NZ",
  },
  {
    name: "Nicaragua",
    short: "NI",
  },
  {
    name: "Niger",
    short: "NE",
  },
  {
    name: "Nigeria",
    short: "NG",
  },
  {
    name: "Niue",
    short: "NU",
  },
  {
    name: "Norfolk Island",
    short: "NF",
  },
  {
    name: "North Korea",
    short: "KP",
  },
  {
    name: "North Macedonia",
    short: "MK",
  },
  {
    name: "Northern Mariana Islands",
    short: "MP",
  },
  {
    name: "Norway",
    short: "NO",
  },
  {
    name: "Oman",
    short: "OM",
  },
  {
    name: "Pakistan",
    short: "PK",
  },
  {
    name: "Palestinian Territory",
    short: "PS",
  },
  {
    name: "Panama",
    short: "PA",
  },
  {
    name: "Papua New Guinea",
    short: "PG",
  },
  {
    name: "Paraguay",
    short: "PY",
  },
  {
    name: "Peru",
    short: "PE",
  },
  {
    name: "Philippines",
    short: "PH",
  },
  {
    name: "Pitcairn",
    short: "PN",
  },
  {
    name: "Poland",
    short: "PL",
  },
  {
    name: "Portugal",
    short: "PT",
  },
  {
    name: "Puerto Rico",
    short: "PR",
  },
  {
    name: "Qatar",
    short: "QA",
  },
  {
    name: "Reunion",
    short: "RE",
  },
  {
    name: "Romania",
    short: "RO",
  },
  {
    name: "Russia",
    short: "RU",
  },
  {
    name: "Rwanda",
    short: "RW",
  },
  {
    name: "S&atilde;o Tom&eacute; and Pr&iacute;ncipe",
    short: "ST",
  },
  {
    name: "Saint Barth&eacute;lemy",
    short: "BL",
  },
  {
    name: "Saint Helena",
    short: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    short: "KN",
  },
  {
    name: "Saint Lucia",
    short: "LC",
  },
  {
    name: "Saint Martin (Dutch part)",
    short: "SX",
  },
  {
    name: "Saint Martin (French part)",
    short: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    short: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    short: "VC",
  },
  {
    name: "Samoa",
    short: "WS",
  },
  {
    name: "San Marino",
    short: "SM",
  },
  {
    name: "Saudi Arabia",
    short: "SA",
  },
  {
    name: "Senegal",
    short: "SN",
  },
  {
    name: "Serbia",
    short: "RS",
  },
  {
    name: "Seychelles",
    short: "SC",
  },
  {
    name: "Sierra Leone",
    short: "SL",
  },
  {
    name: "Singapore",
    short: "SG",
  },
  {
    name: "Slovakia",
    short: "SK",
  },
  {
    name: "Slovenia",
    short: "SI",
  },
  {
    name: "Solomon Islands",
    short: "SB",
  },
  {
    name: "Somalia",
    short: "SO",
  },
  {
    name: "South Africa",
    short: "ZA",
  },
  {
    name: "South Georgia/Sandwich Islands",
    short: "GS",
  },
  {
    name: "South Korea",
    short: "KR",
  },
  {
    name: "South Sudan",
    short: "SS",
  },
  {
    name: "Spain",
    short: "ES",
  },
  {
    name: "Sri Lanka",
    short: "LK",
  },
  {
    name: "Sudan",
    short: "SD",
  },
  {
    name: "Suriname",
    short: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    short: "SJ",
  },
  {
    name: "Swaziland",
    short: "SZ",
  },
  {
    name: "Sweden",
    short: "SE",
  },
  {
    name: "Switzerland",
    short: "CH",
  },
  {
    name: "Syria",
    short: "SY",
  },
  {
    name: "Taiwan",
    short: "TW",
  },
  {
    name: "Tajikistan",
    short: "TJ",
  },
  {
    name: "Tanzania",
    short: "TZ",
  },
  {
    name: "Thailand",
    short: "TH",
  },
  {
    name: "Timor-Leste",
    short: "TL",
  },
  {
    name: "Togo",
    short: "TG",
  },
  {
    name: "Tokelau",
    short: "TK",
  },
  {
    name: "Tonga",
    short: "TO",
  },
  {
    name: "Trinidad and Tobago",
    short: "TT",
  },
  {
    name: "Tunisia",
    short: "TN",
  },
  {
    name: "Turkey",
    short: "TR",
  },
  {
    name: "Turkmenistan",
    short: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    short: "TC",
  },
  {
    name: "Tuvalu",
    short: "TV",
  },
  {
    name: "Uganda",
    short: "UG",
  },
  {
    name: "Ukraine",
    short: "UA",
  },
  {
    name: "United Arab Emirates",
    short: "AE",
  },
  {
    name: "United Kingdom (UK)",
    short: "GB",
  },
  {
    name: "United States (US)",
    short: "US",
  },
  {
    name: "United States (US) Minor Outlying Islands",
    short: "UM",
  },
  {
    name: "Uruguay",
    short: "UY",
  },
  {
    name: "Uzbekistan",
    short: "UZ",
  },
  {
    name: "Vanuatu",
    short: "VU",
  },
  {
    name: "Vatican",
    short: "VA",
  },
  {
    name: "Venezuela",
    short: "VE",
  },
  {
    name: "Vietnam",
    short: "VN",
  },
  {
    name: "Virgin Islands (British)",
    short: "VG",
  },
  {
    name: "Virgin Islands (US)",
    short: "VI",
  },
  {
    name: "Wallis and Futuna",
    short: "WF",
  },
  {
    name: "Western Sahara",
    short: "EH",
  },
  {
    name: "Yemen",
    short: "YE",
  },
  {
    name: "Zambia",
    short: "ZM",
  },
  {
    name: "Zimbabwe",
    short: "ZW",
  },
];
