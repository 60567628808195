import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import { getToken, getAdminToken } from '@/utils/auth'
// console.log('环境变量', process.env.VUE_APP_API_URL)
// const VUE_APP_API_URL = 'http://192.168.1.116:18086/'
const VUE_APP_API_URL = 'https://api.moonstation2050.com'
const service = axios.create({
  baseURL: VUE_APP_API_URL,
  timeout: 3600000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    let path = router.currentRoute.path
    const isToken = (config.headers || {}).isToken === false;
    let token = '';
    if (!path.includes('/adm')) {
      token = getToken()
    } else {
      token = getAdminToken()
    }
    console.log('token', token)
    if (token && !isToken) {
      config.headers['Authorization'] = `${token}`;
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  // 网络请求成功
  (response) => {
    const { code, msg, data } = response.data
    // 业务逻辑错误
    if (code !== 200) {
      Message({
        message: msg || 'Error: 业务错误',
        type: 'warning'
      })
      return Promise.reject(new Error(msg || 'Error: 业务错误'))
    } else {
      // Message.success(msg || 'Success: 请求成功')
      return data
    }
  },
  // 网络请求失败
  async (error) => {
    console.log(error)
    if (error.code === 401) {
      Message({ type: 'warning', message: '登录已过期，请重新登录' })
      // await removeToken()
      router.push('/login')
      return Promise.reject(error)
    } else {
      Message.error(error.response?.data.error || 'Error: 网络错误')
      return Promise.reject(error)
    }
  }
)

export default service
