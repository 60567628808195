
const admin = {
    login: {
        wl: 'Welcome to',
        tip: 'Please sign-in to your account and submit your proposal',
        name: 'Name',
        pwdholder: "..........",
        pwd: 'Password',
        remember: 'Remember Me',
        errorTip: 'This field is required',
        loginBtn: 'Sign in',
        platform: 'New on our platform',
    },
    userPage: {
        columns: {
            name: 'name',
            email: 'email',
            phoneNumber: 'mobile phone',
            region: 'country/region',
            realName: 'Real Name',
            status: 'status'
            // registTime: 'registration time',
        }
    },
    entriesPage: {
        columns: {
            teamName: 'teamName',
            theme: 'theme',
            memberNames: 'memberNames',
            memberEmails: 'memberEmails',
            memberCountries: 'memberCountries',
            memberAffiliations: 'memberAffiliations',
            advisorNames: 'advisorNames',
            advisorEmails: 'advisorEmails',
            worksTitle: 'works title',
            worksfj: 'annex',
            contestresults : 'Contestresults',
            operate: 'operate'
        },
        searchPlaceHolder: 'query value',
        deleteTips: 'Are you sure you want to delete it?',
        cancelTips: 'Are you sure you want to cancel it?',
        deleteBtnText: 'Delete'
    },
    layout: {
        eventManagement: 'Event Management',
        parameterWorks: 'Parameter works',
        siteUsers: 'Site User',
        email: 'Send Emails',
        themeSwitch: 'Theme Switch',
        logout: 'Logout',
        toggexpandleCollapse: 'Expand/Retract',
        expand: 'Expand',
        retract: 'Retract',
    },
    download: 'download works',
    downloadAll: 'download word and file',
    sendEmail: {
        title: 'Title',
        sendType: 'Sending Type',
        emails: 'Emails',
        emailsPlaceHolder: 'Please enter the email address, separated by commas',
        content: 'Content',
        sendTypeList: [
            { value: '1', label: 'All Users' },
            { value: '2', label: 'All Registered Users' },
            { value: '4', label: 'All Submitted Users'},
            { value: '5', label: 'Finalist' },
            { value: '6', label: 'First Prize' },
            { value: '7', label: 'Second Prize' },
            { value: '8', label: 'Third Prize' },
            { value: '9', label: 'Honorable Mention' },
            { value: '3', label: 'Custom' },
        ],
        ruleTips:{
            title1: 'This field is required',
            title2: 'Between 3 and 50 characters in length',
            sendType: 'This field is required',
            emails: 'This field is required'
        }
    }
}

export default admin