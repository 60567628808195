<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'LayoutPage',
    data () {
      return {
      }
    },
    
    computed: {},

    components: {
    },

    created () {},
    
    mounted () {},
    
    methods: {
    },

    watch: {},

    beforeDestroy () {}
  }
</script>

<style lang="scss" scoped>
</style>