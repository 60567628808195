import request from '@/utils/request'

/*  */

/**
 * 管理员登录
 * @param {*} data 
 * @returns 
 */

export const adminLogin = (data) => {
    return request({
        url: '/backUser/login',
        method: 'post',
        data
    })
}


// 网站用户接口
// 获取所有用户
export const getAllUserList = (data) => {
    return request({
        url: '/user/queryPage',
        method: 'post',
        data
    })
}

// 到处用户列表
export const exportUserList = (data) => {
    return request({
        url: '/user/export',
        method: 'post',
        headers: {
            responseType: 'blob'
        },
        data
    })
}


// 参赛作品

export const getCompetitionList = (data) => {
    return request({
        url: '/work/queryPage',
        method: 'post',
        data
    })
}

// 导出参赛作品
export const exportCompetitionList = (data) => {
    return request({
        url: '/work/export',
        method: 'post',
        data
    })
}

// 导出参赛作品2
export const exportCompetitionList2 = data => {
    return request({
        url: '/work/export2',
        method: 'post',
        data
    })
}

// 下载作品 导出文件
export const downloadByNumber = data => {
    return request({
        url: '/work/downloadByNumber?'+data,
        method: 'get'
    })
}

// 获取文件地址
export const exportListIds = data => {
    return request({
        url: '/work/exportListIds',
        method: 'post',
        data
    })
}

// 删除作品
export const deleteWorkById = data => {
    return request({
        url: '/work/delById',
        method: 'post',
        data
    })
}


export const setWorkfinals = data => {
    return request({
        url: '/work/setWorkfinals',
        method: "post",
        data
    })
}