<template>
  <div id="app" ref="app">
    <LayOut></LayOut>
  </div>
</template>

<script>
import LayOut from '@/views/layout/index.vue'
import _ from 'lodash'
export default {
  name: 'App',
  data() {
    return {
      timer: null,
      viewWidth: Number(window.innerWidth),
    }
  },
  computed: {
    viewSize() {
      // if (this.viewWidth > 600) {
      //   return 'large';
      // } else {
      //   return 'small';
      // }
      return 'large'
    }
  },
  provide() {
    return {
      appWidth: () => this.viewWidth,
      appSize: () => this.viewSize
    }
  },
  components: {
    LayOut
  },
  created() {
    // this.updateViewWidth()
  },

  mounted() {
    this.$nextTick(() => {
      // this.resizeWindowWidth();
    })
  },

  methods: {
    // 节流 更新视口宽度
    // updateViewWidth() {
    //   const throttle = () => {
    //     if (!this.timer) {
    //       this.timer = setTimeout(() => {
    //         clearTimeout(this.timer);
    //         this.timer = null;
    //         this.viewWidth = Number(window.innerWidth);
    //         console.log("视口宽度：" + this.viewWidth);
    //       }, 500);
    //     }
    //   }
    //   window.addEventListener('resize', throttle);
    // }

    resizeWindowWidth() {
      const $app = this.$refs.app;
      const standardScale = 1080 / 1920;
      window.addEventListener('resize', _.debounce(() => {
        const docHeight = document.body.clientHeight;
        const docWidth = document.body.clientWidth;
        if (docWidth < 1920) {
          const currentScale = docHeight / docWidth;
          let [scale, translate] = [0, 0];
          if (currentScale < standardScale) {
            // 以高度计算
            scale = docHeight / 1080;
            const shouleWidth = docWidth - shouleWidth;
            const offsetWidth = docWidth - shouleWidth;
            translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px,0)` : ''
          } else {
            // 以宽度计算
            scale = docWidth / 1920;
            const shouleHeight = 1080 * scale;
            const offsetHeight = docHeight - shouleHeight;
            translate = offsetHeight > 0 ? `translate(0,${offsetHeight / 2}px)` : ''

          }
          $app.style.cssText = `
          tranform:scale(${scale});
          transform-origin:top left;
          width:1920px;
          min-height:1080px`
        } else {
          let [scale, translate] = [0, 0]
          scale = docWidth / 1920;
          const shouleHeight = 1080 * scale;
          const offsetHeight = docHeight - shouleHeight
          translate = offsetHeight > 0 ? `translate(0,${offsetHeight / 2}px})` : ''
          $app.style.cssText = `
          tranform:scale(${scale});
          transform-origin:top left;
          width:1920px;
          min-height:1080px;
          `
        }
      }, 300)
      )
    }
  },

  watch: {},

  beforeDestroy() { }
}
</script>

<style>
body {
  overflow: hidden;
}
</style>
