import request from '@/utils/request';

// 登录
export const LoginAPI = (data) => {
    return request({
        url: 'user/login',
        method: 'post',
        data
    })
}

// 注册
export const registerAPI = (data) => {
    return request({
        url: 'user/register',
        method: 'post',
        data
    })
}

// 发送邮件
export const sendEmailAPI = email => {
    return request({
        url: 'user/sendMail?email='+email,
        method: 'get',
    })
}

// 修改密码
export const changePasswordAPI = data => {
    return request({
        url: 'user/changePassword',
        method: 'post',
        data
    })
}

// 重置密码
export const resetPasswordAPI = data => {
    return request({
        url: 'user/resetPassword',
        method: 'post',
        data
    })
}