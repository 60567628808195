const languages = {
    zhCn: 'Chinese',
    en: "English",
    ja: 'Japanese',
    es: "Spanish",
    pl: 'Polish',
    ru: 'Russian',
    fr: 'French',
    pt: 'Portuguese',
    ko: 'Korean'
}

export default languages