import Vue from 'vue'
import App from './App.vue'
import '@/style/index.scss'
import '@/style/style.css'
import '@/style/tailwind.css'
import '@/style/customer.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ElementLocale from 'element-ui/lib/locale'
import '@/style/element-variables.scss'

import VueI18n from 'vue-i18n'
import languagesPkg from '@/languages';

import router from '@/router'

import Vuex from 'vuex'
import store from '@/store'


Vue.use(ElementUI)
Vue.use(VueI18n)
Vue.use(Vuex)

const i18n = new VueI18n({
  messages: languagesPkg,
  locale: 'en',
  fallbackLocale: 'en'
})
ElementLocale.i18n((key, value) => i18n.t(key, value))

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')

