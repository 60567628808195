const home = {
    homeTitle: 'Moon Station 2050',
    homeMiniTitle: 'Future Moon Station Innovation Competition',
    homeContent1P1 : 'The Moon is an important partner of Earth and has always been a mode of attraction forscientists all around the world.Humanity has long desired to return to the Moon and create apermanent lunar station as a launching pad for future scientific space exploration andinterplanetary migration.The construction of a Moon station is an extremely challenging task thatrequires game-changing concepts as well as novel scientific technologies.The Moon StationDesign Competition chooses the lunar South Pole-Aitken Basin as the site,encouragingparticipants to challenge conventional thinking and propose innovative concepts andtechnological solutions for the future construction and operation of the Moon Station.There arefive key themes in this competition.' ,
    homeFooterTitle:'Organizers',
    homeFooterContent1:'Harbin Institute of Technology',
    homeFooterContent2:'Chinese Society of Astronautics',
    homeFooterContent3:'Moon Village Association',
    copyright:'Moon Village Association',
    subTitle: 'Global Innovation Competition'
}

export default home